import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { navigate } from 'gatsby-link'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'
import ShareModal from '../../components/popup/share'
import View from '../../components/presentation/KMITLEngineerAlimni-login'

import authActions from '../../state/auth/actions'
import homeActions from '../../state/home/actions'

class IndexPage extends React.Component {
  async componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    if (window.location.href.search('code=') > 0) {
      if (values.code) {
        this.props.onOAuthLogin(values.code)
      }
    } else if (window.location.href.search('error=') > 0) {
      navigate(
        `/error-auth/?error_description=${values.error_description}=&error=${
          values.error
        }`
      )
    } else {
      this.props.checkAuthorization('/my-club', null, null, {
        onError: e => {
          if (e === 'IS_OAUTH_BY_PASS') {
            navigate('/my-club')
          } else {
            this.props.onLoad()
          }
        },
      })
    }
  }

  reArrayCardData(dataList) {
    const newList = []
    if (dataList.length > 0) {
      dataList.forEach(v => {
        newList.push(...v)
      })
    }
    return newList
  }

  render() {
    if (!this.props.state.readyPage) {
      return <Loading />
    }
    return (
      <Layout>
        <SEO keywords={[`invitree`]} />
        <View
          onJoinFree={this.props.onJoinFree}
          onShare={this.props.onShare}
          onGoDetail={this.props.onGoDetail}
          data={{
            slider_show: this.props.state.slider_show,
            cards: this.props.state.card,
            cardsMobile: this.reArrayCardData(this.props.state.card),
            comment_review: this.props.state.comment_review,
          }}
          dataPrcing={this.props.state.dataPrcing}
          lang={this.props.app.lang}
          locale={this.props.app.locale[this.props.app.lang]}
          forgotPassword={this.props.forgotPassword}
          resetPassword={this.props.resetPassword}
        />
        <ShareModal id={this.props.state.selectedShareID} />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onOAuthLogin: authActions.onOAuthLogin,
      forgotPassword: authActions.forgotPassword,
      resetPassword: authActions.resetPassword,
      onShare: homeActions.onShare,
      onGoDetail: homeActions.onGoDetail,
      onJoinFree: homeActions.onJoinFree,
      onLoad: homeActions.onLoad,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage)
