import React from 'react'
import SignInComponent from '../presentation/KMITL-sign-in'
import ResetPasswordComponent from '../presentation/reset-password'
import RequestJoineClub from '../presentation/request-join-club'
import ModalHandler from '../../utils/modal'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import { ImageBanner } from '../banner'
import CardCarousel from '../card/card-carousel'
import CardPanel from '../card/panel'
import CommentReview from '../comment-review/comment-review'
import PricingTable from '../pricing/pricing'
import DownloadAppsPanel from '../download-apps-panel/download-apps-panel'
import {
  BoxDetail,
  BoxDetailExampleCarousel,
  BoxWrapper,
  TextNoData,
} from '../box'

const style1 = { marginTop: 10, width: '100%' }
const style2 = { textAlign: 'center', padding: '30px 20px 60px 20px' }
const style3 = { textAlign: 'center', padding: '60px 20px 60px 20px' }
const style4 = { width: '100%' }

function gotoPage() {
  const { id, invitation_id, invitation_pin } = queryString.parse(
    window.location.search
  )
  if (
    typeof id !== 'undefined' &&
    typeof invitation_id !== 'undefined' &&
    typeof invitation_pin !== 'undefined'
  ) {
    let text = `id=${id}&invitation_id=${invitation_id}&invitation_pin=${invitation_pin}`
    navigate(`/event-detail/?${text}`)
  } else {
    navigate(`/my-club`)
  }
}

function submitSignIn() {
  ModalHandler.closeModal('modalSignIn')
  gotoPage()
}

const ModalResetPasswordKMITL = props => (
  <div id="modalResetPasswordKMITL" className="modal" style={{ paddingTop: 30, zIndex: 9999, }}>
    <div className="modal-content" style={{ maxWidth: 800 }}>
      <ResetPasswordComponent
        onClose={() => ModalHandler.closeModal('modalResetPasswordKMITL')}
        onSubmit={props.onSubmit}
        onResetPassword={props.onResetPassword}
        lang={props.lang}
        locale={props.locale}
        SelectBanner={props.SelectBanner}
      />
    </div>
  </div>
)

const ModalJoinClubKMITL = props => (
  <div
    id="modalJoinClubKMITL"
    className="modal"
    style={{ paddingTop: 30, display: 'none', zIndex: 9999, }}
  >
    <div className="modal-content" style={{ maxWidth: 900 }}>
      <RequestJoineClub
        lang={props.lang}
        onClose={() => ModalHandler.closeModal('modalJoinClubKMITL')}
        locale={props.locale}
        SelectBanner={props.SelectBanner}
      />
    </div>
  </div>
)

function switchSignInToForgotPassword() {
  ModalHandler.openModal('modalResetPasswordKMITL')
}

function openRquestJoinClub() {
  ModalHandler.openModal('modalJoinClubKMITL')
}

export default class KMITLEngineerAlumniLogin extends React.Component {
  constructor(props) {
    super(props)

    let width = document.documentElement.clientWidth
    let textBtn =
      width <= 800
        ? this.props.locale.START_YOUR_FIRST_EVENT
        : this.props.locale.JOIN_FOR_FREE

    this.state = {
      text: textBtn,
      SelectBanner: 'KMITL',
    }
  }

  display(props) {
    return (
      <div>
        <SignInComponent
          lang={props.lang}
          onForgotPassword={switchSignInToForgotPassword}
          openRequestJoinedClub={openRquestJoinClub}
          onSubmit={submitSignIn}
          onSignFacebook={() => console.log('onSignFacebook')}
          locale={props.locale}
        />

        <ModalResetPasswordKMITL
          locale={props.locale}
          lang={props.lang}
          SelectBanner={this.state.SelectBanner}
          onSubmit={this.props.forgotPassword}
          onResetPassword={this.props.resetPassword}
        />
        <ModalJoinClubKMITL
          locale={props.locale}
          lang={props.lang}
          SelectBanner={this.state.SelectBanner}
        />
      </div>
    )
  }

  render() {
    return this.display(this.props)
  }
}
