import React from 'react'
import Input from '../form/input'
import Button from '../form/buttons'
import Cookies from 'js-cookie'
import {
  AuthenticationHelper,
  signOut,
} from '../../utils/auth-utils'
import { getProfileFromId, getProfile, updateProfile } from '../../utils/api'
import s from 'styled-components'
import TermAndConditionPopup from '../popup/term-condition-popup'
import MediaQuery from 'react-responsive'
import KMITLLogo from '../../images/KMITLLogo.png'
import { navigate } from 'gatsby'
import Modal from 'react-modal'
import IconClose from '../../images/ic_close.svg'

const SpanAgree = s.span`margin-left:2px;color: #892a24 !important;text-decoration: underline !important;    cursor: pointer;`
const style1 = { padding: 10, color: 'red', textAlign: 'center' }
const style2 = { position: 'relative', marginTop: 15 }
const style3 = { textAlign: 'center', padding: 10 }
const style4 = { padding: '7px 5px' }
const style5 = { padding: 10, textAlign: 'center' }
const style6 = { textAlign: 'left', fontSize: '16px', fontWeight: 'bold', width: '80%', maxWidth: 340, margin: '15px auto 0px auto', color: '#887979'}
const style9 = { fontSize: 13 }
const style10 = { width: '100%', maxWidth: 400, margin: '0 auto' }
const style11 = { padding: '10px 10px 30px 0' }
const style12 = { textAlign: 'center', padding: 0 }

export default class KMITLSignInComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: null,
      isLoadding: false,
      isRemember: false,
      showTermAndConditionModal: false,
      ui: 'INDEX',
      pdpaAgree: false,
      storepdpaData: {},
      storeuserDetail: {},
      isJoineRequest: true,
      SelectClub: 'KMITLEngineerAlumni',
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onRemember = this.onRemember.bind(this)
    this.actionAfterPdpaverified = this.actionAfterPdpaverified.bind(this)
    this.checkClubProfile = this.checkClubProfile.bind(this)
    this.callTermAndCondition = this.callTermAndCondition.bind(this)
    this.onTermsClick = this.onTermsClick.bind(this)
    this.onPolicyClick = this.onPolicyClick.bind(this)
    this.closeTermAndConditionPopup = this.closeTermAndConditionPopup.bind(this)
    this.togglePdpaAgree = this.togglePdpaAgree.bind(this)
    this.signOutWhenNotAcceptPDPA = this.signOutWhenNotAcceptPDPA.bind(this)
    this.acceptPdpaAgrrement = this.acceptPdpaAgrrement.bind(this)
  }

  checkClubProfile = async (userDetail) => {
    const resData = await getProfileFromId(userDetail.username)
    if (resData.code == 'OK' && resData.data.user_of == this.state.SelectClub) {
      this.props.onSubmit(userDetail)
    } else {
      this.setState({ showTermAndConditionModal: false })
      this.props.openRequestJoinedClub()
    }
  }

  actionAfterPdpaverified(userDetail) {
    if (this.state.isJoineRequest) {
      this.checkClubProfile(userDetail)
    } else {
      this.props.onSubmit(userDetail)
    }
  }
  
  callTermAndCondition = () => {
    this.setState({ showTermAndConditionModal: true })
  }

  acceptPdpaAgrrement = async (pdpaData) => {
    //console.log(pdpaData)
    const submitPayload = {
      phone_number: pdpaData.phone_number,
      name: pdpaData.name,
      pdpaverified: this.state.pdpaAgree
    }
    const pdpaSubmitResult = await updateProfile(submitPayload)
    if (pdpaSubmitResult.code == 'OK') {
      this.actionAfterPdpaverified(this.state.storeuserDetail)
    } else {
      alert('Something went wrong')
    }
  }
  signOutWhenNotAcceptPDPA = async () => {
    try {
      signOut()
      Cookies.remove('IS_REMEMBER')
      Cookies.remove('_id')
      let currentURL = window.location.pathname
      navigate(currentURL)
    } catch (e) {
      console.log(e)
    }
  }

  onTermsClick = () => this.setState({ ui: 'TERM', showTermAndConditionModal: false })
  onPolicyClick = () => this.setState({ ui: 'POLICY', showTermAndConditionModal: false })
  closeTermAndConditionPopup = () => this.setState({ ui: 'INDEX', showTermAndConditionModal: true })
  togglePdpaAgree = () => this.setState({ pdpaAgree: !this.state.pdpaAgree })

  onSubmit() {
    const Process = () => {
      Cookies.set('REMEMBER_USERNAME', this.state.email)
      Cookies.set('REMEMBER_PASSWORD', this.state.password)
      AuthenticationHelper.signIn({
        username: this.state.email,
        password: this.state.password,
        onSuccess: userDetail => {
          const checkPdpaVerified = async () => {
            const pdpaData = await getProfile()
            if (pdpaData.data.pdpaverified === true) {
              this.actionAfterPdpaverified(userDetail)
            } else {
              this.setState({ storepdpaData: pdpaData.data, storeuserDetail: userDetail }, this.callTermAndCondition())
            }
          }
          checkPdpaVerified()
        },
        onError: errorMessage =>
          this.setState({ error: errorMessage, isLoadding: false }),
      })
    }
    this.setState({ isLoadding: true }, Process)
  }

  onRemember() {
    this.setState({ isRemember: !this.state.isRemember }, () => {
      if (this.state.isRemember) {
        Cookies.set('IS_REMEMBER', 'YES')
      } else {
        Cookies.remove('IS_REMEMBER')
      }
    })
  }


  render() {
    return (
      <div>
        <MediaQuery minWidth={769}>
          <div className="panel-sign-in">
            <div className="panel-sign-in-left">
              <img className="signin-img"
                src={process.env.GATSBY_IMG_SIGN_IN_KMITL}
                alt=""
              />
            </div>
            <div className="panel-sign-in-right-club">
            <div style={style6} ><p>{this.props.locale.SIGNIN_INFO_KMITL}</p></div>
              <div style={style10}>
                <Input.Textbox
                  title={this.props.locale.EMAIL}
                  placeholder={`${this.props.locale.EMAIL.toLowerCase()}...`}
                  disabled={this.state.isLoadding}
                  onChange={email => this.setState({ email, error: null })}
                />
                <Input.Textbox
                  title={this.props.locale.PASSWORD}
                  placeholder={`${this.props.locale.PASSWORD.toLowerCase()}...`}
                  disabled={this.state.isLoadding}
                  type="password"
                  onChange={password => this.setState({ password, error: null })}
                />
                {this.state.error && <p style={style1}>{this.state.error}</p>}
                <div style={style2}>
                  <Input.CheckBox
                    value={this.state.isRemember}
                    onChange={this.onRemember}
                  >
                    {this.props.locale.REMEMBER_ME}
                  </Input.CheckBox>
                  <div
                    className={'link-forgot-password'}
                    onClick={this.props.onForgotPassword}
                  >
                    {this.props.locale.FORGOT_PASSWORD}
                  </div>
                </div>
                <div style={style3}>
                  <div style={style4}>
                    <Button.Button4
                      onClick={this.onSubmit}
                      style={style9}
                      disabled={this.state.isLoadding}
                    >
                      {this.props.locale.SIGN_IN}
                    </Button.Button4>
                  </div>
                </div>
                <div style={style5}>
                  <span className="span-link" onClick={this.props.openRequestJoinedClub}>
                    {this.props.locale.NO_ACCOUNT_SIGN_UP_NOW}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={768}>
          <div>
            <img className="signin-img-mobile"
              src={process.env.GATSBY_IMG_SIGN_IN_KMITL_MOBILE}
              alt=""
            />
            <img className="signin-logo-mobile"
              src={KMITLLogo}
              alt=""
            />
          </div>
          <div className="panel-sign-in-right-mobile">
          <div style={style6} ><p>{this.props.locale.SIGNIN_INFO_KMITL}</p></div>
            <div style={style10}>
              <Input.Textbox
                title={this.props.locale.EMAIL}
                placeholder={`${this.props.locale.EMAIL.toLowerCase()}...`}
                disabled={this.state.isLoadding}
                onChange={email => this.setState({ email, error: null })}
              />
              <Input.Textbox
                title={this.props.locale.PASSWORD}
                placeholder={`${this.props.locale.PASSWORD.toLowerCase()}...`}
                disabled={this.state.isLoadding}
                type="password"
                onChange={password => this.setState({ password, error: null })}
              />
              {this.state.error && <p style={style1}>{this.state.error}</p>}
              <div style={style2}>
                <Input.CheckBox
                  value={this.state.isRemember}
                  onChange={this.onRemember}
                >
                  {this.props.locale.REMEMBER_ME}
                </Input.CheckBox>
                <div
                  className={'link-forgot-password-club'}
                  onClick={this.props.onForgotPassword}
                >
                  {this.props.locale.FORGOT_PASSWORD}
                </div>
              </div>
              <div style={style3}>
                <div style={style4}>
                  <Button.Button4
                    onClick={this.onSubmit}
                    style={style9}
                    disabled={this.state.isLoadding}
                  >
                    {this.props.locale.SIGN_IN}
                  </Button.Button4>
                </div>
                <div style={style5}>
                  <span className="span-link" onClick={this.props.openRequestJoinedClub}>
                    {this.props.locale.NO_ACCOUNT_SIGN_UP_NOW}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <Modal
          isOpen={this.state.showTermAndConditionModal}
          onRequestClose={() => {
            this.setState({ showTermAndConditionModal: false })
            this.signOutWhenNotAcceptPDPA()
          }
          }
          className="information__modal"
          overlayClassName="information__modal__overlay"
        >
          <img
            src={IconClose}
            alt=""
            className="information__modal__close"
            onClick={() => {
              this.signOutWhenNotAcceptPDPA()
              this.setState({ showTermAndConditionModal: false })
            }
            }
          />
          <div style={style12}>
            <Input.CheckBox
              value={this.state.pdpaAgree}
              onChange={this.togglePdpaAgree}
            >
              {this.props.locale.I_AGREE[0]}
              <SpanAgree onClick={this.onTermsClick}>
                {this.props.locale.I_AGREE[1]}
              </SpanAgree>{' '}
              {this.props.locale.I_AGREE[2]}
              <SpanAgree onClick={this.onPolicyClick}>
                {this.props.locale.I_AGREE[3]}
              </SpanAgree>
            </Input.CheckBox>
          </div>
          <Button.Button7
            disabled={!this.state.pdpaAgree}
            style={{ padding: 0, width: 164, marginTop: '15px' }}
            onClick={() => {
              if (this.state.pdpaAgree) {
                this.acceptPdpaAgrrement(this.state.storepdpaData)
              }
            }
            }
          >
            {this.props.locale.I_AGREE[0]}
          </Button.Button7>
        </Modal>
        <TermAndConditionPopup
          closeTermAndConditionPopup={this.closeTermAndConditionPopup}
          ui={this.state.ui}
          lang={this.props.lang}
        />
      </div>
    )
  }
}
